import {
  Box,
  Center,
  Checkbox,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";
import { Controller } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import * as yup from "yup";
import { InputWrapper } from "~/components/molecules/CategoryFields";
import { TITLES } from "~/helpers/titles";

import { FiInfo } from "react-icons/fi";
import "react-international-phone/style.css";
import { useAnamnesis } from "~/hooks/useAnamnesis";

export const STAMMDATEN_FIELDS = [
  {
    slug: "gender",
    label: "Anrede",
    type: "select",
    required: true,
    options: [
      { value: "m", label: "Herr" },
      { value: "w", label: "Frau" },
    ],
    default: "",
  },
  {
    slug: "unformattedTitlePrefix",
    label: "Titel (Vorgestellt)",
    type: "select",
    required: false,
    options: TITLES.map((title) => ({
      value: title,
      label: title,
    })),
    default: "",
  },
  {
    slug: "unformattedTitlePost",
    label: "Titel (Nachgestellt)",
    type: "select",
    required: false,
    options: TITLES.map((title) => ({
      value: title,
      label: title,
    })),
    default: "",
  },
  {
    slug: "firstName",
    label: "Vorname",
    type: "text",
    required: true,
    default: "",
  },
  {
    slug: "lastName",
    label: "Nachname",
    type: "text",
    required: true,
    default: "",
  },
  {
    slug: "email",
    label: "E-Mail",
    type: "email",
    required: true,
    default: "",
  },
  {
    slug: "birthdate",
    label: "Geburtsdatum",
    type: "date",
    required: true,
    default: "",
  },
  {
    slug: "country",
    label: "Land",
    type: "select",
    required: true,
    options: [
      { value: "AUSTRIA", label: "Österreich" },
      { value: "GERMANY", label: "Deutschland" },
      /* { value: "ITALY", label: "Italien" },
      { value: "SWITZERLAND", label: "Schweiz" }, */
    ],
    default: "",
  },
  {
    slug: "street1",
    label: "Straße und Hausnummer",
    type: "text",
    required: false,
    default: "",
  },
  {
    slug: "city",
    label: "Stadt",
    type: "text",
    required: false,
    default: "",
  },
  {
    slug: "zipCode",
    label: "Postleitzahl (PLZ)",
    type: "text",
    required: false,
    default: "",
  },
  {
    slug: "phone",
    label: "Mobiltelefon Nummer",
    type: "phone",
    required: false,
    default: "+43",
  },
];

export const STAMMDATEN_VIEW = [
  ["gender", "unformattedTitlePrefix", "unformattedTitlePost"],
  ["firstName", "lastName"],
  ["email", "birthdate"],
  ["country", "street1"],
  ["city", "zipCode"],
  ["phone"],
];

type FormData = {
  gender: string;
  unformattedTitlePrefix: string;
  unformattedTitlePost: string;
  firstName: string;
  lastName: string;
  email: string;
  birthdate: string;
  country: string;
  street1: string;
  city: string;
  zipCode: string;
  phone: string;
};

export const STAMMDATEN_VALIDATION_SCHEMA = yup.object(
  STAMMDATEN_FIELDS.reduce((acc, field) => {
    if (field.required) {
      acc[field.slug] = yup
        .string()
        .required(`${field.label} ist ein Pflichtfeld`);
    }

    return acc;
  }, {})
);

export const Stammdaten: React.FC<{
  errors: any;
  control: any;
  watch: any;
  reset: any;
}> = ({ errors, control, watch, reset }) => {
  const toast = useToast();
  const { customerData, token, customerId } = useAnamnesis();
  const [noMail, setNoMail] = React.useState(
    customerData?.email?.includes("nomail") ? true : false
  );

  // Prefill form with customer data
  React.useEffect(() => {
    if (!customerData) return;
    if (!customerId) return;
    if (Object.keys(customerData).length === 0) return;

    const formattedCustomerData = Object.entries(customerData).reduce(
      (acc, [key, value]) => {
        if (key === "birthdate") {
          acc[key] = new Date(value).toISOString().split("T")[0];
        } else if (key === "phone") {
          if (!value) {
            acc[key] = "+43";
          } else {
            acc[key] = value;
          }
        } else {
          acc[key] = value === null ? "" : value;
        }

        return acc;
      },
      {}
    );

    reset(formattedCustomerData);
  }, [customerData]);

  return (
    <VStack>
      {STAMMDATEN_VIEW.map((row, rowIndex) => (
        <Stack
          key={`row-${rowIndex}`}
          direction={{ base: "column", md: "row" }}
          spacing={3}
          w="full"
        >
          {row.map((fieldSlug) => {
            const stammdatenField = STAMMDATEN_FIELDS.find(
              (f) => f.slug === fieldSlug
            );

            if (!stammdatenField) return null;

            switch (stammdatenField.type) {
              case "text":
                return (
                  <InputWrapper
                    key={stammdatenField.slug}
                    title={stammdatenField.label}
                    isRequired={stammdatenField.required}
                    isNested={false}
                    isError={errors[stammdatenField.slug]}
                  >
                    <Controller
                      name={stammdatenField.slug}
                      control={control}
                      rules={{
                        required: stammdatenField.required
                          ? `${stammdatenField.label} ist ein Pflichtfeld`
                          : false,
                      }}
                      render={({ field }) => (
                        <Input id={stammdatenField.slug} {...field} />
                      )}
                    />
                    {errors[stammdatenField.slug] && (
                      <Text color="red.500" fontSize="sm">
                        {errors[stammdatenField.slug].message}
                      </Text>
                    )}
                  </InputWrapper>
                );
              case "email":
                return (
                  <InputWrapper
                    key={stammdatenField.slug}
                    title={stammdatenField.label}
                    isRequired={noMail ? false : stammdatenField.required}
                    isNested={false}
                    isError={errors[stammdatenField.slug]}
                  >
                    <Controller
                      name={stammdatenField.slug}
                      control={control}
                      rules={{
                        required: noMail
                          ? false
                          : stammdatenField.required
                          ? `${stammdatenField.label} ist ein Pflichtfeld`
                          : false,
                      }}
                      render={({ field }) => (
                        <VStack w="full" spacing={1} alignItems="flex-start">
                          {!noMail && (
                            <Input
                              id={stammdatenField.slug}
                              {...field}
                              type="email"
                            />
                          )}
                          {/* Checkbox has no mail */}
                          <Checkbox
                            isChecked={noMail}
                            onChange={(e) => {
                              setNoMail(e.target.checked);

                              if (!e.target.checked) {
                                field.onChange(customerData?.email || "");
                              } else {
                                field.onChange(
                                  "nomail+" +
                                    watch("firstName")?.toLowerCase() +
                                    "." +
                                    watch("lastName")?.toLowerCase() +
                                    Math.floor(Math.random() * 1000) +
                                    "@pharmaziegasse.at"
                                );
                              }
                            }}
                          >
                            <HStack alignItems="center" spacing={1}>
                              <Text>Diese Person hat keine E-Mail Adresse</Text>
                              <Box>
                                <Tooltip label="In diesem Fall wird eine generierte E-Mail Adresse erstellt. Du kannst diese später jederzeit bearbeiten.">
                                  <Center
                                    p="1"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Icon as={FiInfo} />
                                  </Center>
                                </Tooltip>
                              </Box>
                            </HStack>
                          </Checkbox>
                        </VStack>
                      )}
                    />
                    {errors[stammdatenField.slug] && (
                      <Text color="red.500" fontSize="sm">
                        {errors[stammdatenField.slug].message}
                      </Text>
                    )}
                  </InputWrapper>
                );
              case "phone":
                return (
                  <InputWrapper
                    key={stammdatenField.slug}
                    title={stammdatenField.label}
                    isRequired={stammdatenField.required}
                    isNested={false}
                    isError={errors[stammdatenField.slug]}
                  >
                    <Controller
                      name={stammdatenField.slug}
                      control={control}
                      rules={{
                        required: stammdatenField.required
                          ? `${stammdatenField.label} ist ein Pflichtfeld`
                          : false,
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          defaultCountry="at"
                          preferredCountries={["at", "de", "it", "ch"]}
                          value={field.value}
                          onChange={(value) => {
                            // Only if the user actually changed something, update it
                            if (value !== field.value) {
                              console.log("value", value);
                              field.onChange(value);
                            }
                          }}
                          inputStyle={{
                            width: "100%",
                            borderTopRightRadius: "0.5rem",
                            borderBottomRightRadius: "0.5rem",
                            outline: "2px solid transparent",
                            outlineOffset: "2px",
                            border: "1px solid --chakra-colors-gray-300",
                            borderLeft: "none",
                            paddingInlineStart: "0.5rem",
                            fontSize: "1rem",
                            paddingInlineEnd: "1rem",
                          }}
                          countrySelectorStyleProps={{
                            style: {
                              borderTopLeftRadius: "0.5rem",
                              borderBottomLeftRadius: "0.5rem",
                              border: "1px solid --chakra-colors-gray-300",
                              borderRight: "none",
                            },
                            flagStyle: {
                              paddingLeft: "0.3rem",
                            },
                          }}
                          style={{
                            width: "100%",
                            height: "2.5rem",
                          }}
                        />
                      )}
                    />
                    {errors[stammdatenField.slug] && (
                      <Text color="red.500" fontSize="sm">
                        {errors[stammdatenField.slug].message}
                      </Text>
                    )}
                  </InputWrapper>
                );
              case "select":
                return (
                  <InputWrapper
                    key={stammdatenField.slug}
                    title={stammdatenField.label}
                    isRequired={stammdatenField.required}
                    isNested={false}
                    isError={errors[stammdatenField.slug]}
                  >
                    <Controller
                      name={stammdatenField.slug}
                      control={control}
                      rules={{
                        required: stammdatenField.required
                          ? `${stammdatenField.label} ist ein Pflichtfeld`
                          : false,
                      }}
                      render={({ field }) => (
                        <Select
                          isSearchable
                          placeholder="Bitte auswählen"
                          menuPlacement="auto"
                          isClearable={stammdatenField.required ? false : true}
                          options={stammdatenField.options}
                          value={stammdatenField.options?.find(
                            (option) => option?.value === field?.value
                          )}
                          // Handle onChange with react-hook-form
                          onChange={(e) => {
                            if (!e) return field.onChange("");

                            field.onChange(e?.value);
                          }}
                        />
                      )}
                    />
                    {errors[stammdatenField.slug] && (
                      <Text color="red.500" fontSize="sm">
                        {errors[stammdatenField.slug].message}
                      </Text>
                    )}
                  </InputWrapper>
                );
              case "date":
                return (
                  <InputWrapper
                    key={stammdatenField.slug}
                    title={stammdatenField.label}
                    isRequired={stammdatenField.required}
                    isNested={false}
                    isError={errors[stammdatenField.slug]}
                  >
                    <Controller
                      name={stammdatenField.slug}
                      control={control}
                      rules={{
                        required: stammdatenField.required
                          ? `${stammdatenField.label} ist ein Pflichtfeld`
                          : false,
                      }}
                      render={({ field }) => (
                        <Input
                          id={stammdatenField.slug}
                          {...field}
                          type="date"
                        />
                      )}
                    />
                    {errors[stammdatenField.slug] && (
                      <Text color="red.500" fontSize="sm">
                        {errors[stammdatenField.slug].message}
                      </Text>
                    )}
                  </InputWrapper>
                );
              default:
                return null;
            }
          })}
        </Stack>
      ))}
    </VStack>
  );
};
