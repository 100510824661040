import {
  Badge,
  Box,
  Button,
  Container,
  Fade,
  HStack,
  Heading,
  Icon,
  Progress,
  ScaleFade,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FiCheck } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { Categories } from "~/components/molecules/Categories";
import CategoryFields from "~/components/molecules/CategoryFields";
import { useAnamnesis } from "~/hooks/useAnamnesis";
import useAuth from "../../../hooks/useAuth";
import { Stammdaten } from "../ProspectData";
import { StammdatenTab } from "~/components/molecules/StammdatenTab";

export const Welcome: React.FC<{
  start: () => void;
}> = ({ start }) => {
  const userDummy = {
    firstName: "John",
    lastName: "Doe",
  };

  return (
    <Box
      flex={1}
      height="100%"
      p="5"
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      <ScaleFade in>
        <Text fontSize="2xl">Anamnese für</Text>
      </ScaleFade>
      <ScaleFade in delay={0.1}>
        <Heading as="h2" size="md">
          {userDummy.firstName} {userDummy.lastName}
        </Heading>
      </ScaleFade>
      <Button
        mt="5"
        onClick={start}
        size="xl"
        w={{
          base: "full",
          md: "auto",
        }}
        colorScheme="blue"
        variant="solid"
      >
        Anamnese starten
      </Button>
    </Box>
  );
};

export const Anamnesis = () => {
  const { user, logout } = useAuth();
  const {
    anamnesisData,
    isSaving,
    isInit,
    isDraft,
    activeCategory,
    token,
    customerId,
  } = useAnamnesis();

  const { colorMode } = useColorMode();

  const [isSaved, setIsSaved] = React.useState(false);

  // If isDraft changes, set isSaved to true for 2 seconds
  React.useEffect(() => {
    if (!isSaving && !isSaved && isInit) {
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
      }, 2000);
    }
  }, [isSaving]);

  const toast = useToast();

  return (
    <Container
      maxW="container.xl"
      flex={1}
      position="relative"
      height="100%"
      px={{
        base: "0",
        md: "5",
      }}
    >
      {isInit ? (
        <Box>
          <Box
            mb="5"
            // Always stay on top, even when scrolling
            position="sticky"
            top="0"
            zIndex="sticky"
            bg="white"
          >
            <Box height="25px" bg={isDraft ? "orange.200" : "white"}>
              {!isDraft && (
                <HStack
                  as={Fade}
                  in={isSaved}
                  position="absolute"
                  w="full"
                  h="25px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={FiCheck} color="green.500" />
                  <Text fontSize="sm" color="green.500" fontWeight="bold">
                    Gespeichert
                  </Text>
                </HStack>
              )}
              {isSaving && (
                <Progress
                  size="xs"
                  isIndeterminate
                  colorScheme={isDraft ? "orange" : "blue"}
                  rounded="none"
                  height="25px"
                  bg="transparent"
                  position={isDraft ? "absolute" : "relative"}
                  zIndex={isDraft ? "sticky" : "unset"}
                  top="0"
                  left="0"
                  right="0"
                />
              )}
              {isDraft && (
                <Box
                  position="absolute"
                  w="full"
                  h="25px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isSaved ? (
                    <HStack>
                      <Icon as={FiCheck} color="orange.800" />
                      <Text fontSize="sm" color="orange.800" fontWeight="bold">
                        Gespeichert
                      </Text>
                    </HStack>
                  ) : (
                    <Text fontSize="sm" color="orange.800" fontWeight="bold">
                      Entwurf
                    </Text>
                  )}
                </Box>
              )}
            </Box>
            <Categories />
          </Box>
          <Box
            px={{
              base: "5",
              md: "0",
            }}
            pb="10"
          >
            {activeCategory === "stammdaten" ? (
              <StammdatenTab />
            ) : (
              <CategoryFields />
            )}
          </Box>
        </Box>
      ) : (
        <Box
          flex={1}
          height="100%"
          p="5"
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          {/* <Welcome start={() => setStarted(true)} /> */}
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
          />
          <Text mt="5">Lade Anamnese...</Text>
          {(process.env.NODE_ENV !== "production" ||
            process.env.REACT_APP_BASEURL?.includes("dev")) && (
            <VStack>
              <Badge
                colorScheme={
                  process.env.REACT_APP_BASEURL?.includes("dev")
                    ? "orange"
                    : "red"
                }
                variant="solid"
                mt="5"
              >
                {process.env.REACT_APP_BASEURL?.includes("dev") ? (
                  <Text fontSize="sm" color="orange.50" fontWeight="bold">
                    Entwicklungsumgebung
                  </Text>
                ) : (
                  <Text fontSize="sm" color="red.50" fontWeight="bold">
                    LIVEUMGEBUNG
                  </Text>
                )}
              </Badge>
              <Text
                fontSize="xs"
                color="gray.500"
                textAlign="center"
                fontWeight="bold"
              >
                {process.env.REACT_APP_BASEURL}
              </Text>
            </VStack>
          )}
        </Box>
      )}
    </Container>
  );
};
