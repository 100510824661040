import { Box, Button, HStack, Icon, VStack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { FiCheck, FiChevronRight } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import { sq } from "~/api";
import {
  STAMMDATEN_FIELDS,
  STAMMDATEN_VALIDATION_SCHEMA,
  Stammdaten,
} from "~/components/pages/ProspectData";
import { useAnamnesis } from "~/hooks/useAnamnesis";

export const StammdatenTab: React.FC = () => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    next,
    setIsDirtyCustomerData,
    setCustomerData,
    setCustomerId,
    token,
    customerId,
  } = useAnamnesis();

  const {
    control,
    watch,
    getValues,
    reset,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields, isSubmitting },
    trigger,
  } = useForm<FormData>({
    defaultValues: STAMMDATEN_FIELDS.forEach((field) => {
      return { [field.slug]: field.default };
    }),
    resolver: yupResolver(STAMMDATEN_VALIDATION_SCHEMA),
  });

  // Update isDirtyCustomerData
  React.useEffect(() => {
    if (isDirty) {
      setIsDirtyCustomerData(true);
    } else {
      setIsDirtyCustomerData(false);
    }
  }, [isDirty]);

  const onSubmit = async (data: FormData) => {
    // Only submit dirty fields (using dirtyFields)
    const dirtyData = Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

    const normalizedData = Object.keys(dirtyData).reduce((acc, key) => {
      acc[key] = dirtyData[key]?.trim() || "";
      return acc;
    }, {});

    // We need to see if the user actually typed in a phone number or if its only the dial code
    if (normalizedData.phone) {
      if (normalizedData.phone.length <= 3) {
        normalizedData.phone = "";
      }
    }

    const [customer, errors] = await sq.mutate(
      (data) => {
        const d = data.customerCustomer({
          id: customerId,
          input: normalizedData,
        });

        return d?.customer?.id;
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );

    if (errors) {
      toast({
        title: "Fehler beim Speichern",
        description: "Bitte überprüfen Sie Ihre Eingaben.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      reset({
        ...getValues(),
      });

      return;
    }

    if (customer) {
      setCustomerData({
        ...getValues(),
        ...dirtyData,
      });

      if (!customerId) {
        // Set customerId
        setCustomerId(customer);

        // Reset isDirtyCustomerData
        setIsDirtyCustomerData(false);

        // Reset dirty fields
        reset({
          ...getValues(),
          ...dirtyData,
        });

        // Go next (override dirty check)
        next(true);
      }
    } else {
      // Reset dirty fields
      reset({
        ...getValues(),
        ...dirtyData,
      });
    }
  };

  // On unmount of component reset form
  React.useEffect(() => {
    return () => {
      reset();

      // Reset isDirtyCustomerData
      setIsDirtyCustomerData(false);
    };
  }, []);

  return (
    <Box>
      <Stammdaten
        errors={errors}
        control={control}
        reset={reset}
        watch={watch}
      />
      <VStack
        w="full"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        mt={5}
        mb={10}
      >
        <HStack w="full" justifyContent="center">
          {isDirty && (
            <Button
              colorScheme="blue"
              w={{
                base: "full",
                md: "auto",
              }}
              size="xl"
              rightIcon={<Icon as={FiCheck} />}
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
            >
              {customerId ? "Aktualisieren" : "Speichern"}
            </Button>
          )}
          {customerId && (
            <Button
              colorScheme="gray"
              w={{
                base: "full",
                md: "auto",
              }}
              size="xl"
              //isDisabled={isDirtyCustomerData}
              rightIcon={<Icon as={FiChevronRight} />}
              onClick={() => {
                // We want to validate the form without submitting it
                trigger().then((isValid) => {
                  if (isValid) {
                    next();
                  }
                });
              }}
            >
              Weiter
            </Button>
          )}
        </HStack>
      </VStack>
    </Box>
  );
};
