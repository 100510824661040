import { createContext } from "react";
import { UserType } from "../api/src/schema.generated";

// TODO: modify return options from signIn, signOut, signUp and add sendPasswordResetEmail and confirmPasswordReset functions
export type AuthContextType =
  | {
      isAuthenticated: boolean;
      isLoading: boolean;
      user: UserType | null;
      updateUser: (u: Partial<UserType>) => UserType | null;
      getUser: () => void;
      logout: () => void;
    }
  | undefined;

export const AuthContext = createContext<AuthContextType>(undefined);
