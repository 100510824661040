//#region > Imports
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Anamnesis } from "./components/pages/Anamnesis";
import React from "react";
//#endregion

// Scroll to top of the page
const scrollTop = () => {
  window.scrollTo(0, 0);
};

//#region > Components
const PageRoutes = () => {
  const location = useLocation();

  // If route changes, scroll to top
  React.useEffect(() => {
    scrollTop();
  }, [location]);

  return (
    <Routes>
      {/* public routes */}
      <Route path="/" element={<Anamnesis />} />
      {/* catch all */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
//#endregion

export default PageRoutes;
