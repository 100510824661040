import { theme as proTheme } from "@chakra-ui/pro-theme";
import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import * as React from "react";
import { ModalWrapper } from "./components/organisms/modals/ModalWrapper";
import { DisclosuresProvider } from "./hooks/useDisclosure";

import useAuth from "./hooks/useAuth";
import Routes from "./Routes";
import { useJsApiLoader } from "@react-google-maps/api";
import { AnamnesisProvider } from "./hooks/useAnamnesis";

export const App = () => {
  const { user } = useAuth();

  const [libraries] = React.useState(["places"]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
  });

  const themeMemo = React.useMemo(() => {
    return extendTheme({
      ...proTheme,
      styles: {
        global: (props: any) => ({
          ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
            overflow: "visible !important",
          },
          body: {
            bg: "white",
          },
        }),
      },
      colors: {
        ...proTheme.colors,
        blue: {
          "50": "#e5eef4",
          "100": "#c0d5e6",
          "200": "#9dbcd5",
          "300": "#79a3c8",
          "400": "#5b92c4",
          "500": "#4081c0",
          "600": "#3975b4",
          "700": "#3064a2",
          "800": "#265492",
          "900": "#133976",
        },
        green: {
          "50": "#d6ebe7",
          "100": "#9ecdc3",
          "200": "#62ac9c",
          "300": "#1e8b77",
          "400": "#00755f",
          "500": "#005f48",
          "600": "#00553f",
          "700": "#004732",
          "800": "#003926",
          "900": "#00210d",
        },
      },
      components: {
        ...proTheme.components,
        Progress: {
          baseStyle: {
            filledTrack: {
              bg: "#9ecdc3",
            },
          },
        },
      },
      /* semanticTokens: {
        ...proTheme.semanticTokens,
        colors: {
          ...proTheme.semanticTokens.colors,
          "bg-subtle-gray": {
            _light: "bg-subtle",
            _dark: "gray.800",
          },
          "bg-subtle-hover": {
            _light: "gray.100",
            _dark: "gray.600",
          },
        },
      }, */
    });
  }, []);

  return (
    <ChakraProvider theme={themeMemo}>
      <DisclosuresProvider>
        <AnamnesisProvider>
          <ColorModeScript />
          <ModalWrapper>
            <Routes />
          </ModalWrapper>
        </AnamnesisProvider>
      </DisclosuresProvider>
    </ChakraProvider>
  );
};
