import {
  Badge,
  Box,
  Button,
  Fade,
  HStack,
  Icon,
  IconButton,
  Progress,
  ScaleFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAnamnesis } from "~/hooks/useAnamnesis";

const CategoryButton: React.FC<{
  c: number;
  cat: any;
  isActive: boolean;
  errorCount: number;
  progressValue: number;
  setActiveCategory: (id: string) => void;
}> = ({ c, cat, isActive, errorCount, progressValue, setActiveCategory }) => {
  return (
    <Box
      as={Fade}
      in
      delay={c * 0.05}
      key={c}
      bg={
        progressValue === 100
          ? isActive
            ? "green.100"
            : "green.50"
          : errorCount > 0
          ? isActive
            ? "red.200"
            : "red.100"
          : isActive
          ? "gray.300"
          : "gray.200"
      }
      flex="1"
      px="3"
      py="1"
      textAlign="center"
      color="gray.600"
      onClick={() => setActiveCategory(cat.id)}
      cursor="pointer"
      transition="all 0.2s ease"
      borderBottom="2px solid"
      borderColor={
        progressValue === 100
          ? isActive
            ? "green.200"
            : "green.100"
          : errorCount > 0
          ? isActive
            ? "red.300"
            : "red.200"
          : isActive
          ? "gray.400"
          : "transparent"
      }
      _hover={{
        opacity: 0.1,
      }}
      position="relative"
    >
      <Text
        fontSize={{
          base: "md",
          md: "xl",
        }}
        fontWeight="bold"
        mb="-5px"
      >
        {c + 1}
      </Text>
      {errorCount > 0 && (
        <Badge
          colorScheme="red"
          variant="solid"
          transition="all 0.2s ease"
          opacity={{
            base: "0",
            md: "1",
          }}
          size="sm"
          fontSize="0.6em"
          position="absolute"
          top="5px"
          right="5px"
        >
          {errorCount}
        </Badge>
      )}
      <Text
        display={{
          base: "none",
          md: "block",
        }}
      >
        {cat?.name}
      </Text>
    </Box>
  );
};

export const Categories: React.FC = () => {
  const {
    anamnesisData,
    activeCategory,
    setActiveCategory,
    next,
    prev,
    hasNext,
    hasPrev,
    isSaving,
    errorsPerCategory,
    progress,
  } = useAnamnesis();

  const activeCategoryData = React.useMemo(() => {
    return anamnesisData?.find((cat, c) => cat.id === activeCategory);
  }, [anamnesisData, activeCategory]);

  const activeCategoryProgress = React.useMemo(() => {
    // If the active category is "stammdaten" we return null because it has no progress
    if (activeCategory === "stammdaten") return null;

    const progressForCategory = progress?.find(
      (p) =>
        p.category ===
        anamnesisData?.find((cat) => cat.id === activeCategory)?.id
    );

    if (!progressForCategory) return;

    return progressForCategory.required === 0
      ? 100
      : progressForCategory
      ? (progressForCategory.filledRequired / progressForCategory.required) *
        100
      : 0;
  }, [progress, activeCategory]);

  if (!anamnesisData) return null;

  return (
    <VStack w="full" spacing={0} userSelect="none">
      <HStack w="full" spacing="2px">
        <CategoryButton
          c={0}
          cat={{ name: "Stammdaten" }}
          errorCount={0}
          progressValue={0}
          isActive={activeCategory === "stammdaten"}
          setActiveCategory={() => {
            setActiveCategory("stammdaten");
          }}
        />
        {anamnesisData?.map((cat, c) => {
          const errorCount =
            errorsPerCategory[c] && Object.keys(errorsPerCategory[c])?.length;

          const progressForCategory = progress?.find(
            (p) => p.category === cat.id
          );

          // We need to calculate the percentage of progress for this category using "filledRequired" and "required"
          const progressValue = progressForCategory
            ? progressForCategory?.required === 0
              ? 100
              : progressForCategory
              ? (progressForCategory?.filledRequired /
                  progressForCategory?.required) *
                100
              : 0
            : 0;

          return (
            <CategoryButton
              key={c}
              c={c + 1}
              cat={cat}
              isActive={activeCategory === cat.id}
              errorCount={errorCount}
              progressValue={progressValue}
              setActiveCategory={setActiveCategory}
            />
          );
        })}
      </HStack>
      <VStack as={Fade} in delay={0.1} w="full" spacing={0}>
        <HStack
          bg="gray.100"
          w="full"
          py="2"
          px="3"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flex="1">
            {hasPrev && (
              <IconButton
                aria-label="Previous"
                onClick={prev}
                size="lg"
                icon={<Icon as={MdChevronLeft} color="gray.600" />}
              />
            )}
          </Box>
          <VStack flex="1" textAlign="center" color="gray.600" spacing={0}>
            {activeCategoryProgress !== null ? (
              <>
                <Text
                  fontWeight="bold"
                  fontSize={{
                    base: "lg",
                    md: "xl",
                  }}
                  fontFamily="oxfam-headline"
                >
                  {activeCategoryData?.name}
                </Text>
                <Text>{activeCategoryProgress?.toFixed()}%</Text>
              </>
            ) : (
              <>
                <Text
                  fontWeight="bold"
                  fontSize={{
                    base: "lg",
                    md: "xl",
                  }}
                  fontFamily="oxfam-headline"
                >
                  Stammdaten
                </Text>
              </>
            )}
          </VStack>
          <Box flex="1" display="flex" justifyContent="flex-end">
            {hasNext && (
              <IconButton
                aria-label="Next"
                onClick={next}
                size="lg"
                icon={<Icon as={MdChevronRight} color="gray.600" />}
              />
            )}
          </Box>
        </HStack>
        {/* Progress */}
        <Box w="full">
          <Progress
            value={activeCategoryProgress}
            bg="gray.200"
            height={3}
            isAnimated
            borderRadius="0"
          />
        </Box>
      </VStack>
    </VStack>
  );
};
