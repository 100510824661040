import { createContext, useEffect, useMemo, useState } from "react";

import { storage } from "snek-query";
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY, sq } from "../api/index";
import { UserType } from "../api/src/schema.generated";
import { AuthContext } from "./AuthContext";
import * as amplitude from "@amplitude/analytics-browser";

export const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<Partial<UserType> | null>(null);

  const updateUser = (update: Partial<UserType>) => {
    setUser((prev) => ({ ...prev, ...update }));
  };

  // Check if the user's token is in AsyncStorage
  const checkToken = async () => {
    // Check if the user's token is in AsyncStorage
    const token = await storage.get(AUTH_TOKEN_KEY);

    // If the user's token is in AsyncStorage, set isAuthenticated to true
    if (token) {
      setIsAuthenticated(true);
      setIsLoading(false);

      checkAuth();
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  };

  const getUser = async () => {
    try {
      const [res, errors] = await sq.query((data) => {
        const userMe = data.userMe;

        return {
          id: userMe?.id,
          firstName: userMe?.firstName,
          lastName: userMe?.lastName,
          emailAddress: userMe?.emailAddress!,
          uType: userMe?.uType!, // 1 = User, 7 = Admin, 8 = Owner, 9 = Jesus
          isActive: userMe?.isActive,
        };
      });

      if (res?.id) {
        // If the user is authenticated, set isAuthenticated to true and set the user object
        !isAuthenticated && setIsAuthenticated(true);

        // Store uType in Local Storage for easy access for protected routes (preventing loading)
        localStorage.setItem("uType", res.uType.toString());

        if (user) {
          updateUser(res);
        } else {
          // Identify user in Amplitude
          amplitude.setUserId(res.emailAddress);

          const identifyEvent = new amplitude.Identify();
          identifyEvent.set("role", res.uType);

          amplitude.identify(identifyEvent);

          setUser(res);
        }
      } else {
        // If the user is not authenticated, set isAuthenticated to false and clear the user object
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      // TODO: Log error
    } finally {
      setIsLoading(false);
    }
  };

  // Check if the user is authenticated on mount
  const checkAuth = () => {
    try {
      // Call your authentication service here to check if the user is authenticated
      getUser();
    } catch (error) {
      // TODO: Log error

      /* Toast.show({
        type: "error",
        text1: "Fehler",
        text2:
          "Beim Überprüfen der Authentifizierung ist ein Fehler aufgetreten.",
      }); */

      // If the user is not authenticated, set isAuthenticated to false and clear the user object
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  const handleLogout = async () => {
    // Remove the user's token from AsyncStorage
    await storage.remove(AUTH_TOKEN_KEY);
    await storage.remove(AUTH_REFRESH_TOKEN_KEY);

    // Set the authentication state to false
    setIsAuthenticated(false);
    setUser(null);

    // Redirect the user to the login screen
    // @ts-ignore
    //navigation.navigate("Login");
  };

  const logout = async () => {
    try {
      const refreshToken = await storage.get(AUTH_REFRESH_TOKEN_KEY);

      if (refreshToken) {
        await sq.mutate((data) => data.userLogout({ refreshToken })?.revoked);
      }
    } catch (error) {
      // TODO: Log error
    } finally {
      handleLogout();
    }
  };

  const getToken = async () => {
    // Check if token is valid by calling the refresh token mutation
    try {
      const refreshToken = await storage.get(AUTH_REFRESH_TOKEN_KEY);

      if (refreshToken) {
        // If the refresh token exists, call the refresh token mutation
        const [res, errors] = await sq.mutate((data) => {
          const tokens = data.userRefreshToken({ refreshToken });

          return {
            token: tokens!.token,
            refreshToken: tokens!.refreshToken,
          };
        });

        // If the token and refresh token exist, save them to AsyncStorage and return the token
        if (res?.token && res?.refreshToken) {
          // Save the new tokens to AsyncStorage
          await storage.set(AUTH_TOKEN_KEY, res?.token);
          await storage.set(AUTH_REFRESH_TOKEN_KEY, res?.refreshToken);

          return res?.token;
        } else {
          // TODO: Log error

          // If the refresh token fails, logout
          logout();
        }
      } else {
        // If the refresh token does not exist, logout
        logout();

        // TODO: Log error
      }
    } catch (error) {
      // TODO: Log error
    }
  };

  useEffect(() => {
    // Check if the user is authenticated on mount
    checkToken();
  }, []);

  // Return the authentication state and user object
  const value = useMemo(() => {
    return {
      isAuthenticated,
      isLoading,
      uTypeStored: localStorage.getItem("uType")
        ? parseInt(localStorage.getItem("uType")!)
        : null,
      updateUser,
      getUser,
      user,
      logout,
    };
  }, [isAuthenticated, isLoading, updateUser, getUser, user, logout]);

  // This seems to already be handled by SplashScreen
  if (isLoading) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
