import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

// DOM bindings for React Router
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./context/AuthProvider";
import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";

//> Redux
// Provider
import { Provider as ReduxProvider } from "react-redux";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

Sentry.init({
  dsn: "https://f7c22803c5ca1c3e27e669d9116ba46e@sentry.cronit.io/6",
  release: process.env.REACT_APP_VERSION,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,
        "https://p5.kanbon.cloud/graphql/",
        "https://dev.p5.kanbon.cloud/graphql/",
      ],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 1.0, //  Capture 100% of the sessions
});

root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
