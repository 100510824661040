import { makeSnekQuery, storage } from "snek-query";
import { Query, Mutation } from "./schema.generated.ts";
import * as Sentry from "@sentry/react";

export const AUTH_TOKEN_KEY = "auth-t";
export const AUTH_REFRESH_TOKEN_KEY = "auth-rt";

type GraphqlError = {
  message: string;
  path: string[];
  extensions?: {
    code: string;
    userMessage?: string;
  };
};

const URL = process.env.REACT_APP_BASEURL + "/graphql/";

const checkErrorMessage = (errors: GraphqlError[], message: string) => {
  return errors.some((error) => error.message === message);
};

const handleGraphQLErrors = (graphQLErrors: GraphqlError[]) => {
  const CustomErrorMap = {
    LOGIN_FAILED: {
      message: "Bitte gib eine gültige E-Mail-Adresse und ein Passwort ein.",
      path: ["userLogin"],
    },
  };

  const compareError = (error: GraphqlError, customError: GraphqlError) => {
    const matchMessage = error.message === customError.message;
    const matchPath = error?.path?.toString() === customError?.path?.toString();

    return matchMessage && matchPath;
  };

  if (graphQLErrors && graphQLErrors.length) {
    for (const error of graphQLErrors) {
      let sendToSentry = true;

      if (compareError(error, CustomErrorMap.LOGIN_FAILED)) {
        // TODO: Show login failed message

        sendToSentry = false;
      } else {
        const userMessage = error.extensions && error.extensions?.userMessage;
      }

      // TODO: Show error message

      if (sendToSentry) {
        Sentry.captureException(error);
      }
    }
  }
};

export const sq = makeSnekQuery(
  { Query, Mutation },
  {
    apiURL: URL,
    middlewares: [
      async ({ context }) => {
        // Get token from header (GET param) from url
        // The url looks like this: foo.com/<token>/customerId?>
        const url = window.location.pathname;
        const urlParts = url.split("/");

        const token = (await storage.get(AUTH_TOKEN_KEY)) ?? urlParts[1];

        if (!token) return;

        context.headers = {
          ...context.headers,
          Authorization: `JWT ${token}`,
        };
      },
    ],
    onError: async ({ operation, graphQLErrors, forward }) => {
      const removeTokensAndRedirectToLogin = async (reason: string) => {
        await storage.remove(AUTH_TOKEN_KEY);
        await storage.remove(AUTH_REFRESH_TOKEN_KEY);
      };

      if (graphQLErrors && graphQLErrors.length) {
        if (checkErrorMessage(graphQLErrors, "Invalid refresh token")) {
          //console.log("Invalid refresh token");
          await removeTokensAndRedirectToLogin("Invalid refresh token");
        } else if (
          checkErrorMessage(graphQLErrors, "Signature has expired") ||
          checkErrorMessage(graphQLErrors, "Error decoding signature")
        ) {
          const refreshToken = await storage.get(AUTH_REFRESH_TOKEN_KEY);

          if (refreshToken) {
            /* const data = await sq.mutate(({ data }) => {
                const login = data.userRefreshToken({
                  refreshToken,
                });
  
                const tokens = {
                  token: login?.token ?? null,
                  refreshToken: login?.refreshToken ?? null,
                };
  
                return tokens;
              }); */

            // Temp fix for snek query 0.0.39
            const data = await fetch(URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                query: `mutation {
                    userRefreshToken(refreshToken: "${refreshToken}") {
                      token
                      refreshToken
                    }
                  }`,
              }),
            })
              .then((res) => res.json())
              .then((res) => {
                const login = res.data.userRefreshToken;

                const tokens = {
                  token: login?.token ?? null,
                  refreshToken: login?.refreshToken ?? null,
                };

                return tokens;
              })
              .catch((err) => {
                removeTokensAndRedirectToLogin("Unable to restore auth");
              });

            if (data && data.token && data.refreshToken) {
              await storage.set(AUTH_TOKEN_KEY, data.token);
              await storage.set(AUTH_REFRESH_TOKEN_KEY, data.refreshToken);

              // This is the only path that will actually retry the operation that failed
              // due to the signature expiring.

              // update the context with the new token
              const context = operation.getContext();

              operation.setContext({
                ...context,
                headers: {
                  ...context.headers,
                  Authorization: `JWT ${data.token}`,
                },
              });

              return forward(operation);
            } else {
              removeTokensAndRedirectToLogin("Unable to restore auth");
            }
          } else {
            removeTokensAndRedirectToLogin("Unable to restore auth");
          }
        } else {
          handleGraphQLErrors(graphQLErrors);
        }
      }
    },
  }
);
