import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AnamnesisProgressType,
  CustomerType,
} from "../api/src/schema.generated";

import { Text, useToast } from "@chakra-ui/react";
import { sq } from "~/api";
import { useDisclosures } from "./useDisclosure";
import * as Sentry from "@sentry/react";

export type AnamnesisFieldInputType =
  | "TEXT"
  | "RADIO"
  | "MULTISELECT"
  | "FILE"
  | "INFO";

interface IAnamnesisCondition {
  slug: string;
  value: number;
}

interface IAnamnesisOption {
  name: string;
  value: number;
  image?: string;
}

export interface IAnamnesisField {
  slug: string;
  required: boolean;
  options: IAnamnesisOption[];
  name: string;
  inputType: AnamnesisFieldInputType;
  helpText?: string;
  conditions?: IAnamnesisCondition[];
}

export interface IAnamnesisCategory {
  name: string;
  id: string;
  icon?: string;
  color: string;
  anamnesisFields: IAnamnesisField[];
}

interface AnamnesisContextType {
  isInit: boolean;
  anamnesisData: IAnamnesisCategory[];
  activeCategory: string; // ID of category
  isSaving: boolean;
  errorsPerCategory: { [key: number]: string[] };
  setErrorsPerCategory: (errors: { [key: number]: string[] }) => void;
  setActiveCategory: (category: string) => void;
  next: (override?: boolean) => void;
  prev: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  isDraft: boolean;
  customerId: string | undefined;
  token: string | undefined;
  saveAnamnesisField: (field: string, value: string) => void;
  submitFinishedAnamnesis: () => void;
  prefillData: AnamnesisPrefillItem[] | null;
  progress: AnamnesisProgressType[] | null;
  setCustomerId: (customerId: string) => void;
  getAnamnesisData: () => void;
  customerData: Partial<CustomerType> | null;
  setCustomerData: (data: Partial<CustomerType>) => void;
  isDirtyCustomerData: boolean;
  setIsDirtyCustomerData: (isDirty: boolean) => void;
}

interface AnamnesisPrefillItem {
  slug: string;
  history: {
    value: string;
  }[];
}

const AnamnesisContext = createContext<AnamnesisContextType | undefined>(
  undefined
);

//const TESTING_CUSTOMER_ID = "Q3VzdG9tZXJUeXBlOjExNzA=";

export const AnamnesisProvider = ({ children }: { children: ReactNode }) => {
  const toast = useToast();
  const disc = useDisclosures();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [prefillData, setPrefillData] = useState<AnamnesisPrefillItem[] | null>(
    null
  );
  const [isInit, setIsInit] = useState<boolean>(false);
  const [progress, setProgress] = useState<AnamnesisProgressType[] | null>(
    null
  );
  const [token, setToken] = useState<string | undefined>(undefined);
  const [customerId, setCustomerId] = useState<string | undefined>(undefined);
  const [customerData, setCustomerData] =
    useState<Partial<CustomerType> | null>(null);
  // This is the "isDirty" state from the react-hook-form
  // We need to keep track of this, because we won't let the user leave the page if there are unsaved changes
  const [isDirtyCustomerData, setIsDirtyCustomerData] =
    useState<boolean>(false);

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [finishedOnce, setFinishedOnce] = useState<boolean>(false);

  const [anamnesisData, setAnamnesisData] = useState<
    IAnamnesisCategory[] | null
  >(null);

  const [errorsPerCategory, setErrorsPerCategory] = useState<{
    [key: number]: string[];
  }>({});

  const [activeCategory, setActiveCategory] = useState<string | null>(
    "stammdaten"
  );

  const saveAnamnesisField = async (
    fields: { field: string; value: string }[]
  ) => {
    try {
      setIsSaving(true);

      console.log("Attempting to save anamnesis field", fields);

      if (!customerId) return;

      if (!fields.length) return;

      const [data, errors] = await sq.mutate(
        (data) => {
          return data
            .customerBulkUpdateAnamnesis({
              customerId,
              draft: finishedOnce ? false : true,
              input: fields?.map((field) => ({
                slug: field?.field,
                value: Array.isArray(field.value)
                  ? JSON.stringify(field.value)
                  : field.value,
              })),
            })
            ?.customer?.anamnesisProgressPerCategory?.map((progress) => ({
              category: progress?.category?.id,
              total: progress?.total,
              required: progress?.required,
              filled: progress?.filled,
              filledRequired: progress?.filledRequired,
            }));
        },
        {
          headers: {
            //"anamnesis-token": `JWT ${token}`, // Only on magic link
            Authorization: `JWT ${token}`,
          },
        }
      );

      if (errors && errors.length > 0) {
        throw new Error(errors[0]?.message);
      }

      if (data) {
        setProgress(data);

        if (!finishedOnce && !isDraft) setIsDraft(true);
      }
    } catch (error: any) {
      Sentry.captureMessage("Error while saving anamnesis field", {
        extra: {
          error,
          fields,
        },
      });

      toast({
        title: `Fehler beim Speichern`,
        description: `Fehler beim Speichern der Anamnese`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const getAnamnesisData = async () => {
    const [data, errors] = await sq.query(
      (data) => {
        return data.anamnesisCategories({})?.edges?.map((edge) => ({
          id: edge?.node?.id,
          name: edge?.node?.name,
          color: edge?.node?.color,
          icon: edge?.node?.icon,
          anamnesisFields: edge?.node?.anamnesisFields?.map((field) => ({
            slug: field?.slug,
            required: field?.required,
            name: field?.name,
            inputType: field?.inputType,
            helpText: field?.helpText,
            options: field?.options?.map((option) => ({
              name: option?.name,
              value: option?.value,
              image: option?.image,
              help_text: option?.help_text,
            })),
            conditions: field?.conditions?.map((condition) => ({
              slug: condition?.slug,
              value: condition?.value,
            })),
          })),
        }));
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );

    if (errors) {
      Sentry.captureMessage("Error while loading anamnesis data", {
        extra: {
          errors,
        },
      });

      toast({
        title: `Fehler beim Laden der Anamnese`,
        description: errors[0]?.message,
        status: "error",
        position: "top",
        duration: 10000,
        isClosable: true,
      });

      throw new Error(errors[0]?.message);
    }

    if (data) {
      setAnamnesisData(data);

      // Set first category as active (id of category)
      // Disabled because we want to start with "stammdaten" as first category (right now at least)
      // Might want to keep this for future use
      //setActiveCategory(data[0].id);

      return true;
    }
  };

  const identifyUser = async () => {
    try {
      const [data, errors] = await sq.query(
        (data) => {
          return data.userMe?.emailAddress;
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      if (errors) {
        throw new Error(errors[0]?.message);
      }

      if (data) {
        Sentry.setUser({
          email: data,
        });
      }
    } catch (error) {
      Sentry.captureMessage("Error while identifying user", {
        extra: {
          error,
        },
      });
    }
  };

  const loadCustomerData = async () => {
    try {
      const [customer, errors] = await sq.query(
        (q) => {
          const d = q.customerCustomer({
            id: customerId,
          });

          return {
            email: d?.email,
            phone: d?.phone,
            firstName: d?.firstName,
            lastName: d?.lastName,
            birthdate: d?.birthdate,
            country: d?.country,
            street1: d?.street1,
            city: d?.city,
            zipCode: d?.zipCode,
            gender: d?.gender,
            unformattedTitlePrefix: d?.unformattedTitlePrefix,
            unformattedTitlePost: d?.unformattedTitlePost,
          };
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      if (errors) {
        Sentry.captureMessage("Error while loading customer data", {
          extra: {
            errors,
          },
        });

        errors?.map((error) => {
          const message =
            error?.extensions?.userMessage ||
            "Stammdaten konnten nicht geladen werden";

          toast({
            title: "Fehler",
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        });

        throw new Error(errors[0]?.message);
      }

      if (customer) {
        setCustomerData(customer);
      }
    } catch (error) {
    } finally {
      return true;
    }
  };

  const getCustomerAnamnesisData = async () => {
    const [data, errors] = await sq.query(
      (data) => {
        const d = data.customerCustomer({ id: customerId });

        return {
          anamnesis: d?.anamnesis?.map((anamnesis) => ({
            slug: anamnesis?.slug,
            history: anamnesis
              ?.history({
                last: 1,
              })
              ?.edges?.map((edge) => ({
                value: edge?.node?.value,
              })),
          })),
          progress: d?.anamnesisProgressPerCategory?.map((progress) => ({
            category: progress?.category?.id,
            total: progress?.total,
            required: progress?.required,
            filled: progress?.filled,
            filledRequired: progress?.filledRequired,
          })),
          isDraft: d?.anamnesisDraftOpened,
          finishedOnce: d?.anamnesisFinishedOnce,
        };
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );

    if (errors) {
      Sentry.captureMessage("Error while loading customer anamnesis data", {
        extra: {
          errors,
        },
      });

      toast({
        title: `Fehler beim Laden der Anamnese`,
        description: errors[0]?.message,
        status: "error",
        position: "top",
        duration: 10000,
        isClosable: true,
      });

      throw new Error(errors[0]?.message);
    }

    if (data) {
      setPrefillData(data.anamnesis);
      setProgress(data.progress);
      setIsDraft(data.finishedOnce ? false : data.isDraft);
      setFinishedOnce(data.finishedOnce);
    }

    return true;
  };

  const submitFinishedAnamnesis = async () => {
    try {
      setIsSaving(true);

      if (!customerId) return;

      const [data, errors] = await sq.mutate(
        (data) => {
          return data.customerBulkUpdateAnamnesis({
            customerId,
            finish: true,
            draft: false,
          })?.customer?.anamnesisDraftOpened;
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      if (errors) {
        throw new Error(errors[0]?.message);
      }

      if (!data) {
        toast({
          title: `Anamnese abgeschlossen`,
          description: `Die Anamnese wurde erfolgreich abgeschlossen`,
          status: "success",
          duration: 5000,
          position: "top",
          isClosable: true,
        });

        if (isDraft) {
          setIsDraft(false);
        }

        // Tell parent, that the anamnesis is finished and the user can be redirected
        window.parent.postMessage(
          {
            type: "FINISHED_ANAMNESIS",
            payload: {
              customerId,
            },
          },
          "*"
        );
      }
    } catch (error: any) {
      Sentry.captureMessage("Error while submitting anamnesis", {
        extra: {
          error,
        },
      });

      toast({
        title: `Fehler beim Absenden`,
        description: `Fehler beim Absenden der Anamnese`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);

      return true;
    }
  };

  const init = async () => {
    try {
      // Create an array of promises to run in parallel
      const promises = [];

      // Only add the anamnesis data query if it's not already loaded
      if (anamnesisData === null) {
        promises.push(getAnamnesisData());
      }

      // Identify the user for Sentry
      promises.push(identifyUser());

      // Add the customer data query to the promises array
      if (!customerData && customerId) promises.push(loadCustomerData());

      // Add the customer anamnesis data query to the promises array
      if (customerId) promises.push(getCustomerAnamnesisData());

      // Run both tasks in parallel and wait for their completion
      const results = await Promise.all(promises);

      // Check if both were successful (results are assumed to be `true` or data)
      const allSuccessful = results.every((result) => result !== false);

      // If both promises resolved successfully, set the initialization flag
      if (allSuccessful) {
        setIsInit(true);
      }
    } catch (error) {
      // Handle any errors (if necessary)
      console.error("Error during initialization:", error);

      Sentry.captureMessage("Error during initialization", {
        extra: {
          error,
        },
      });

      toast({
        title: `Fehler beim Laden der Anamnese`,
        description: `Fehler beim Laden der Anamnese`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const goNext = () => {
    // Get index of active category
    const index = anamnesisData.findIndex((cat) => cat.id === activeCategory);

    // Check if it's the last category
    if (index === anamnesisData.length - 1) return;

    // Set next category as active
    setActiveCategory(anamnesisData[index + 1].id);
  };

  // Load customer data if customerId is set
  useEffect(() => {
    if (!token) return;

    init();

    // If no customer id is set, we need to set the dirty state
    // This means, that the user can't change categories until a customerId is set
    if (!customerId) {
      setIsDirtyCustomerData(true);
    }
  }, [customerId, token]);

  // Unlock changing categories if a customerId is set
  useEffect(() => {
    if (customerId) {
      setIsDirtyCustomerData(false);
    }
  }, [customerId]);

  // When active category changes, scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeCategory]);

  // Listen for messages from parent window
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { type, payload } = event.data;

      if (type === "SET_TOKEN") {
        setToken(payload.token);
      } else if (type === "SET_TOKEN_AND_CUSTOMER_ID") {
        setToken(payload.token);
        setCustomerId(payload.customerId);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const value = {
    isInit,
    anamnesisData,
    activeCategory,
    setActiveCategory: (category: string) => {
      if (isDirtyCustomerData) {
        // Show alert
        if (customerId) {
          disc?.alertModal?.onOpen({
            title: "Ungespeicherte Änderungen",
            description: (
              <Text>
                Du hast ungespeicherte Änderungen. Wenn du fortfährst, gehen
                diese verloren.
              </Text>
            ),
            colorScheme: "red",
            confirmText: "Änderungen verwerfen",
            onConfirm: () => {
              setActiveCategory(category);

              // Close alert
              disc?.alertModal?.onClose();
            },
          });
        } else {
          disc?.alertModal?.onOpen({
            title: "Kundendaten fehlen",
            description: (
              <Text>
                Bitte fülle zuerst die Stammdaten aus, bevor du fortfährst.
              </Text>
            ),
            noCancel: true,
            colorScheme: "blue",
            confirmText: "Okay",
            onConfirm: () => {
              // Close alert
              disc?.alertModal?.onClose();
            },
          });
        }

        return;
      }

      setActiveCategory(category);
    },
    isSaving,
    next: useCallback(
      (override?: boolean) => {
        if (!anamnesisData) return;

        // Check if customer data is dirty
        if (isDirtyCustomerData && !override) {
          // Show alert
          if (customerId) {
            disc?.alertModal?.onOpen({
              title: "Ungespeicherte Änderungen",
              description: (
                <Text>
                  Du hast ungespeicherte Änderungen. Wenn du fortfährst, gehen
                  diese verloren.
                </Text>
              ),
              colorScheme: "red",
              confirmText: "Änderungen verwerfen",
              onConfirm: () => {
                goNext();

                // Close alert
                disc?.alertModal?.onClose();
              },
            });
          } else {
            disc?.alertModal?.onOpen({
              title: "Kundendaten fehlen",
              description: (
                <Text>
                  Bitte fülle zuerst die Stammdaten aus, bevor du fortfährst.
                </Text>
              ),
              noCancel: true,
              colorScheme: "blue",
              confirmText: "Okay",
              onConfirm: () => {
                // Close alert
                disc?.alertModal?.onClose();
              },
            });
          }

          return;
        }

        // Get index of active category
        goNext();
      },
      [anamnesisData, activeCategory, isDirtyCustomerData]
    ),
    prev: useCallback(() => {
      if (!anamnesisData) return;

      // Get index of active category
      const index = anamnesisData.findIndex((cat) => cat.id === activeCategory);

      // Check if it's the first category
      if (index === 0) {
        // Go to "stammdaten"
        setActiveCategory("stammdaten");

        return;
      }

      // Set previous category as active
      setActiveCategory(anamnesisData[index - 1].id);
    }, [anamnesisData, activeCategory]),
    hasNext: anamnesisData
      ? anamnesisData.findIndex((cat) => cat.id === activeCategory) <
        anamnesisData.length - 1
      : false,
    hasPrev: activeCategory !== "stammdaten",
    errorsPerCategory,
    setErrorsPerCategory,
    saveAnamnesisField,
    submitFinishedAnamnesis,
    isDraft,
    prefillData,
    progress,
    customerId,
    token,
    setCustomerId,
    getAnamnesisData,
    customerData,
    setCustomerData,
    // IsDirty state from react-hook-form
    isDirtyCustomerData,
    setIsDirtyCustomerData,
  };

  return (
    <AnamnesisContext.Provider value={value}>
      {children}
    </AnamnesisContext.Provider>
  );
};

export const useAnamnesis = () => {
  const context = useContext(AnamnesisContext);
  if (context === undefined) {
    throw new Error("useAnamnesis must be used within an AnamnesisProvider");
  }
  return context;
};
