import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Fade,
  FormControl,
  FormControlProps,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FiCheck,
  FiChevronRight,
  FiInfo,
  FiSend,
  FiTrash,
} from "react-icons/fi";
import { useAnamnesis } from "~/hooks/useAnamnesis";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FileUpload } from "~/components/atoms/formInputs/FileUpload";

type FormData = Record<string, any>;

interface InputWrapperProps extends FormControlProps {
  children: React.ReactNode;
  isNested?: boolean;
  title?: string;
  isError?: boolean;
}

export const InputWrapper: React.FC<InputWrapperProps> = ({
  children,
  isNested,
  title,
  isError,
  ...props
}) => {
  return (
    <FormControl
      as={Fade}
      in
      pl={isNested ? 4 : 0}
      borderLeft={isNested ? "3px solid" : undefined}
      borderColor={isNested ? "blue.500" : undefined}
      {...props}
    >
      {title && (
        <FormLabel
          fontWeight="bold"
          fontSize="md"
          color={isError ? "red.500" : "gray.900"}
        >
          {title}
        </FormLabel>
      )}
      {children}
    </FormControl>
  );
};

const CategoryFields: React.FC = () => {
  const {
    anamnesisData: categories,
    activeCategory: activeCategoryId,
    errorsPerCategory,
    isSaving,
    hasNext,
    setActiveCategory,
    submitFinishedAnamnesis,
    setErrorsPerCategory,
    next,
    isDraft,
    saveAnamnesisField,
    prefillData,
  } = useAnamnesis();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const fieldsWithoutConditions = categories.flatMap((category) =>
    category.anamnesisFields.filter((field) => !field.conditions)
  );

  const [validationSchema, setValidationSchema] = React.useState<any>(
    yup.object({
      ...fieldsWithoutConditions.reduce((acc, field) => {
        if (field.required) {
          acc[field.slug] = yup
            .string()
            .required(`${field.name} ist ein Pflichtfeld`);
        }

        return acc;
      }, {}),
    })
  );

  const [fieldVisibility, setFieldVisibility] = React.useState<
    Record<string, boolean>
  >({}); // Store the visibility of each field

  // Setup useForm with default values
  const {
    control,
    watch,
    getValues,
    reset,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    trigger,
  } = useForm<FormData>({
    defaultValues: {
      ...categories.reduce((acc, category) => {
        category.anamnesisFields.forEach((field) => {
          acc[field.slug] =
            prefillData?.find((item) => item.slug === field.slug)?.history[0]
              ?.value || "";
        });
        return acc;
      }, {} as FormData),
    },
    resolver: yupResolver(validationSchema),
  });

  const checkFieldVisibility = (changedFieldSlug: string) => {
    let visibleFields = {};

    // Clear errors for fields that have changed
    Object.keys(dirtyFields).forEach((field) => {
      clearErrors(field);
    });

    // Get all fields that have a condition that depends on the changed field
    const dependentFields = categories
      .flatMap((category) => category.anamnesisFields)
      .filter((field) =>
        field.conditions?.some(
          (condition) => condition.slug === changedFieldSlug
        )
      );

    // Update the visibility of the dependent fields
    dependentFields.forEach((field) => {
      if (field.conditions?.length > 0) {
        const isVisible = field.conditions.some((condition) => {
          const value = watch(condition.slug);

          return condition.value == value;
        });

        visibleFields[field.slug] = isVisible;
      }
    });

    setFieldVisibility((prev) => ({ ...prev, ...visibleFields }));

    // Update validation schema based on the new visibility
    updateSchema(Object.keys(visibleFields));
  };

  // Set initial visibility of fields based on conditions (do not listen to changes)
  React.useEffect(() => {
    let initialVisibility = {};

    categories.forEach((category) => {
      category.anamnesisFields.forEach((field) => {
        if (field.conditions?.length > 0) {
          const isVisible = field.conditions.some((condition) => {
            const value = getValues(condition.slug);

            return condition.value == value;
          });

          initialVisibility[field.slug] = isVisible;
        }
      });
    });

    setFieldVisibility(initialVisibility);

    // Update validation schema based on initial visibility
    updateSchema(Object.keys(initialVisibility));
  }, []);

  // Update validation schema based on field visibility
  const updateSchema = (visibleFields: string[]) => {
    let schema = visibleFields.reduce((acc, field) => {
      const fieldSchema = categories
        .flatMap((category) => category.anamnesisFields)
        .find((f) => f.slug === field);

      if (!fieldSchema) return acc;

      if (fieldSchema.required) {
        acc[field] = yup
          .string()
          .required(`${fieldSchema.name} ist ein Pflichtfeld`);
      }

      return acc;
    }, {});

    // Add the fields without conditions to the schema
    fieldsWithoutConditions.forEach((field) => {
      if (!schema[field.slug]) {
        if (field.required) {
          schema[field.slug] = yup
            .string()
            .required(`${field.name} ist ein Pflichtfeld`);
        }
      }
    });

    setValidationSchema(yup.object().shape(schema));
  };

  // Get the active category data
  const activeCategory = categories.find(
    (category) => category.id === activeCategoryId
  );

  const onSubmit = async (data: FormData) => {
    const changedData = Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

    saveAnamnesisField(
      Object.entries(changedData).map(([key, value]) => ({
        field: key,
        value,
      }))
    );

    console.log("Data submitted", changedData);
  };

  const _errorsPerCategory = React.useMemo(() => {
    return categories.map((category) => {
      return category.anamnesisFields.reduce((acc, field) => {
        if (errors[field.slug]) {
          acc[field.slug] = errors[field.slug];
        }

        return acc;
      }, {});
    });
  }, [Object.keys(errors)?.length]);

  const onSubmitFinish = async (data: FormData) => {
    await submitFinishedAnamnesis();

    setIsSubmitting(false);
  };

  const onInvalidFinish = async (errors: any) => {
    const firstError = Object.keys(errors).find((key) => errors[key]);

    if (!firstError) return;

    // Get the category index with the first error
    const firstCategoryWithError = categories?.findIndex((category) =>
      category.anamnesisFields?.some((field) => field.slug === firstError)
    );

    if (firstCategoryWithError > -1) {
      // Get category id
      const categoryId = categories[firstCategoryWithError].id;

      if (!categoryId) return;

      // Set active category to the first category with errors
      setActiveCategory(categoryId);
    }

    setIsSubmitting(false);
  };

  // Update the visibility of fields when the form changes
  React.useEffect(() => {
    setErrorsPerCategory(_errorsPerCategory);
  }, [_errorsPerCategory]);

  // Auto save the form data when the form changes (wait 1s before saving)
  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    const handleAutosave = async () => {
      if (Object.keys(dirtyFields).length === 0) {
        return; // Skip if no fields are dirty
      }

      Object.keys(dirtyFields).forEach((field) => {
        if (errors[field]) clearErrors(field);
      });

      const values = getValues();
      await onSubmit(values);
      reset(values, {
        keepErrors: true, // Keep the errors when resetting the form
      }); // Consider the implication of resetting fields here
    };

    const subscription = watch(() => {
      clearTimeout(timer);

      console.log("Form changed", dirtyFields);

      timer = setTimeout(handleAutosave, 1000);
    });

    return () => {
      clearTimeout(timer);
      subscription.unsubscribe();
    };
  }, [handleSubmit, dirtyFields]);

  if (!activeCategory) return null; // or some error UI

  return (
    <VStack spacing={5}>
      {activeCategory.anamnesisFields.map((anamnesisField, index) => {
        // Check if the field should be visible using fieldVisibility
        if (fieldVisibility[anamnesisField.slug] === false) return null;

        switch (anamnesisField.inputType) {
          case "TEXT":
            return (
              <InputWrapper
                key={anamnesisField.slug}
                title={anamnesisField.name}
                isRequired={anamnesisField.required}
                isNested={anamnesisField.conditions?.length > 0}
                isError={errors[anamnesisField.slug] ? true : false}
              >
                <Controller
                  name={anamnesisField.slug}
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      id={anamnesisField.slug}
                      {...field}
                      minH="80px"
                      maxH="300px"
                    />
                  )}
                />
              </InputWrapper>
            );
          case "STRING":
            return (
              <InputWrapper
                key={anamnesisField.slug}
                title={anamnesisField.name}
                isRequired={anamnesisField.required}
                isNested={anamnesisField.conditions?.length > 0}
                isError={errors[anamnesisField.slug] ? true : false}
              >
                <Controller
                  name={anamnesisField.slug}
                  control={control}
                  rules={{
                    required: anamnesisField.required
                      ? `${anamnesisField.name} ist ein Pflichtfeld`
                      : undefined,
                  }}
                  render={({ field }) => (
                    <Input id={anamnesisField.slug} {...field} />
                  )}
                />
              </InputWrapper>
            );
          case "SHOP":
            return (
              <InputWrapper
                key={anamnesisField.slug}
                title={anamnesisField.name}
                isRequired={anamnesisField.required}
                isNested={anamnesisField.conditions?.length > 0}
                isError={errors[anamnesisField.slug] ? true : false}
              >
                <Controller
                  name={anamnesisField.slug}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      id={anamnesisField.slug}
                      // Allow deselecting the radio button
                      onClick={(e) => {
                        const target = e.target as HTMLInputElement;
                        if (target.checked) {
                          target.checked = false;
                          onChange("");

                          checkFieldVisibility(anamnesisField.slug);
                        }
                      }}
                      onChange={(nextValue: string) => {
                        onChange(nextValue || "");

                        checkFieldVisibility(anamnesisField.slug);
                      }}
                      value={value}
                    >
                      <Stack
                        direction={{
                          base: "column",
                          md: "row",
                        }}
                        spacing={4}
                      >
                        {anamnesisField.options?.map((option, optIndex) => {
                          const isChecked = value === option.value.toString();

                          return (
                            <Box
                              key={optIndex}
                              bg={isChecked ? "blue.50" : "gray.50"}
                              flex="1"
                              p="4"
                              minH="100px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              w="full"
                              borderWidth="2px"
                              borderRadius="lg"
                              borderColor={isChecked ? "blue.100" : "gray.100"}
                              userSelect="none"
                              cursor="pointer"
                              transition="all 0.2s ease"
                              _hover={{
                                bg: isChecked ? "blue.100" : "gray.100",
                              }}
                              position="relative"
                              onClick={() => {
                                if (isChecked) {
                                  onChange("");
                                } else {
                                  onChange(option.value.toString());
                                }

                                checkFieldVisibility(anamnesisField.slug);
                              }}
                            >
                              {/* Most popular tag */}
                              {optIndex === 1 && (
                                <Badge
                                  colorScheme="blue"
                                  position="absolute"
                                  top="2"
                                  right="2"
                                  size="sm"
                                  rounded="md"
                                >
                                  Beliebt
                                </Badge>
                              )}
                              <VStack>
                                <Text
                                  fontSize="md"
                                  fontWeight="bold"
                                  textAlign="center"
                                >
                                  {option.name}
                                </Text>
                                <Text
                                  fontSize="sm"
                                  fontWeight="normal"
                                  textAlign="center"
                                  color={isChecked ? "blue.600" : "gray.500"}
                                >
                                  {option.help_text}
                                </Text>
                              </VStack>
                            </Box>
                          );
                        })}
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </InputWrapper>
            );
          case "RADIO":
            return (
              <InputWrapper
                key={anamnesisField.slug}
                title={anamnesisField.name}
                isRequired={anamnesisField.required}
                isNested={anamnesisField.conditions?.length > 0}
                isError={errors[anamnesisField.slug] ? true : false}
              >
                <Controller
                  name={anamnesisField.slug}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      id={anamnesisField.slug}
                      // Allow deselecting the radio button
                      onClick={(e) => {
                        const target = e.target as HTMLInputElement;
                        if (target.checked) {
                          target.checked = false;
                          onChange("");

                          checkFieldVisibility(anamnesisField.slug);
                        }
                      }}
                      onChange={(nextValue: string) => {
                        onChange(nextValue || "");

                        checkFieldVisibility(anamnesisField.slug);
                      }}
                      value={value}
                    >
                      <Stack
                        direction={{
                          base: "column",
                          md: anamnesisField.options?.some(
                            (option) => option.image
                          )
                            ? "column"
                            : "row",
                        }}
                        spacing={4}
                        w="full"
                      >
                        {anamnesisField.options?.map((option, optIndex) => (
                          <Radio
                            key={optIndex}
                            value={option.value?.toString()}
                            bg="white"
                          >
                            <Stack
                              alignItems={{
                                base: "flex-start",
                                md: "center",
                              }}
                              justifyContent="center"
                              direction={{
                                base: "column",
                                md: option?.image ? "column" : "row",
                              }}
                              spacing={{
                                base: 0,
                                md: 2,
                              }}
                            >
                              <HStack w="full" justifyContent="space-between">
                                {option?.image && (
                                  <Image
                                    src={option.image}
                                    alt={option.name}
                                    w="75px"
                                    h="75px"
                                  />
                                )}
                                <Text fontSize="md" fontWeight="normal">
                                  {option.name}
                                </Text>
                              </HStack>
                              {/* Tooltip */}
                              {option.help_text && (
                                <>
                                  <Box
                                    display={{
                                      base: "none",
                                      md: "flex",
                                    }}
                                  >
                                    <Tooltip label={option.help_text} hasArrow>
                                      <Box>
                                        <Icon
                                          as={FiInfo}
                                          color="gray.500"
                                          w={4}
                                          h={4}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </Box>
                                  <Box>
                                    <Text
                                      display={{
                                        base: "block",
                                        md: "none",
                                      }}
                                      fontSize="xs"
                                      color="gray.500"
                                      fontWeight="normal"
                                    >
                                      {option.help_text}
                                    </Text>
                                  </Box>
                                </>
                              )}
                            </Stack>
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </InputWrapper>
            );
          case "FILE":
            return (
              <InputWrapper
                key={anamnesisField.slug}
                title={anamnesisField.name}
                isRequired={anamnesisField.required}
                isNested={anamnesisField.conditions?.length > 0}
                isError={errors[anamnesisField.slug] ? true : false}
              >
                <Controller
                  name={anamnesisField.slug}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Box>
                      {value ? (
                        <VStack>
                          <Image
                            src={value}
                            maxH="300px"
                            maxW="100%"
                            rounded="lg"
                          />
                          <Button
                            colorScheme="gray"
                            leftIcon={<Icon as={FiTrash} />}
                            size="sm"
                            onClick={() => {
                              onChange("");
                              checkFieldVisibility(anamnesisField.slug);
                            }}
                          >
                            Bild entfernen
                          </Button>
                        </VStack>
                      ) : (
                        <FileUpload
                          onFileUpload={(filesInfo) => {
                            console.log("Files uploaded", filesInfo);

                            onChange(filesInfo?.[0]?.dataUrl || "");
                            checkFieldVisibility(anamnesisField.slug);
                          }}
                        />
                      )}
                    </Box>
                  )}
                />
              </InputWrapper>
            );
          case "MULTISELECT":
            return (
              <InputWrapper
                key={anamnesisField.slug}
                title={anamnesisField.name}
                isRequired={anamnesisField.required}
                isNested={anamnesisField.conditions?.length > 0}
                isError={errors[anamnesisField.slug] ? true : false}
              >
                <Controller
                  name={anamnesisField.slug}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Stack
                      direction={{
                        base: "column",
                        md: "row",
                      }}
                      spacing={4}
                    >
                      {anamnesisField.options?.map((option, optIndex) => (
                        <Checkbox
                          key={optIndex}
                          isChecked={value.includes(option.value?.toString())}
                          value={option.value?.toString()}
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            if (target.checked) {
                              onChange([...value, target.value]);
                            } else {
                              onChange(value.filter((v) => v !== target.value));
                            }

                            checkFieldVisibility(anamnesisField.slug);
                          }}
                        >
                          <Stack
                            alignItems={{
                              base: "flex-start",
                              md: "center",
                            }}
                            justifyContent="center"
                            direction={{
                              base: "column",
                              md: "row",
                            }}
                            spacing={{
                              base: 0,
                              md: 2,
                            }}
                          >
                            <Text fontSize="md" fontWeight="normal">
                              {option.name}
                            </Text>
                            {/* Tooltip */}
                            {option.help_text && (
                              <>
                                <Box
                                  display={{
                                    base: "none",
                                    md: "flex",
                                  }}
                                >
                                  <Tooltip label={option.help_text} hasArrow>
                                    <Box>
                                      <Icon
                                        as={FiInfo}
                                        color="gray.500"
                                        w={4}
                                        h={4}
                                      />
                                    </Box>
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <Text
                                    display={{
                                      base: "block",
                                      md: "none",
                                    }}
                                    fontSize="xs"
                                    color="gray.500"
                                    fontWeight="normal"
                                  >
                                    {option.help_text}
                                  </Text>
                                </Box>
                              </>
                            )}
                          </Stack>
                        </Checkbox>
                      ))}
                    </Stack>
                  )}
                />
              </InputWrapper>
            );
          case "INFO":
            return (
              <HStack
                key={index}
                p="4"
                w="full"
                borderWidth="2px"
                borderRadius="lg"
                bg="blue.50"
                borderColor="blue.100"
              >
                <Icon as={FiInfo} color="blue.300" w={5} h={5} />
                <p>{anamnesisField.name}</p>
              </HStack>
            );
          default:
            return (
              <VStack
                key={anamnesisField.slug}
                p="4"
                w="full"
                borderWidth="2px"
                borderRadius="lg"
                bg="red.100"
                borderColor="red.200"
              >
                <Text color="red.500" fontWeight="bold" fontSize="md">
                  Ups! 🤔 Das Feld "{anamnesisField.name}" hat einen unbekannten
                  Typen: "{anamnesisField.inputType}".
                </Text>
                <Text fontSize="sm">
                  Das sollte nicht passieren. Bitte melde das Problem deinem
                  Ansprechpartner.
                </Text>
              </VStack>
            );
        }
      })}
      <VStack
        w="full"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        mt={5}
        mb={10}
      >
        {/* {!isDraft && (
          <Fade in>
            <Text color="gray.500">
              <strong>Entwurf:</strong> Deine Eingaben werden automatisch
              gespeichert.
            </Text>
          </Fade>
        )} */}
        {/* Submit button on last page */}
        {!hasNext ? (
          <HStack w="full" justifyContent="center">
            <Button
              colorScheme="blue"
              w={{
                base: "full",
                md: "auto",
              }}
              size="xl"
              rounded="xl"
              onClick={() => {
                setIsSubmitting(true);

                handleSubmit(onSubmitFinish, onInvalidFinish)();
              }}
              leftIcon={<Icon as={FiSend} />}
              isLoading={isSubmitting}
            >
              {isSubmitting ? "Wird gesendet..." : "Absenden"}
            </Button>
          </HStack>
        ) : (
          <HStack w="full" justifyContent="center">
            <Button
              colorScheme="gray"
              w={{
                base: "full",
                md: "auto",
              }}
              size="xl"
              rightIcon={<Icon as={FiChevronRight} />}
              onClick={next}
            >
              Weiter
            </Button>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default CategoryFields;
