import { useContext } from "react";
import { UserType } from "../api/src/schema.generated";
import { AuthContext } from "../context/AuthContext";

const useAuth = () => {
  return useContext<{
    isAuthenticated: boolean;
    isLoading: boolean;
    uTypeStored: number;
    updateUser: (update: Partial<UserType>) => void;
    getUser: () => Promise<void>;
    user: Partial<UserType> | null;
    logout: () => Promise<void>;
  }>(AuthContext);
};

export default useAuth;
