import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { AlertModalData } from "../ModalWrapper";

export const AlertModal = (
  props: ModalProps & {
    data: AlertModalData;
  }
) => {
  const { isOpen, onClose, data } = props;

  const cancelRef = React.useRef<any>();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {data?.title}
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>{data?.description}</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            {!data?.noCancel && (
              <Button ref={cancelRef} onClick={onClose}>
                Abbrechen
              </Button>
            )}
            <Button
              colorScheme={data?.colorScheme || "red"}
              onClick={data?.onConfirm}
              ml={3}
            >
              {data.confirmText || "Löschen"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
