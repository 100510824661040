import React from "react";
import { useDisclosures } from "../../../../hooks/useDisclosure";
import { AlertModal } from "../Alert";
import { Tutorial } from "../Tutorial";

interface DisclosuresProviderProps {
  children: React.ReactNode;
}

export type AlertModalData = {
  title: string;
  description: string;
  colorScheme?: string;
  confirmText?: string;
  isLoading?: boolean;
  onConfirm?: () => void;
  noCancel?: boolean;
};

export const ModalWrapper = (props: DisclosuresProviderProps) => {
  const { children } = props;

  const disc = useDisclosures();

  return (
    <>
      {disc.tutorialModal && (
        <Tutorial
          isOpen={disc.tutorialModal.isOpen}
          onClose={disc.tutorialModal.onClose}
        />
      )}
      {disc.alertModal && disc.alertModal?.isOpen && (
        <AlertModal
          isOpen={disc.alertModal.isOpen}
          onClose={disc.alertModal.onClose}
          data={disc.alertModal.modalContent as AlertModalData}
        />
      )}
      {children}
    </>
  );
};
