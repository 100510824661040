import { Box, Text } from "@chakra-ui/react";
import { readAndCompressImage } from "browser-image-resizer";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";

type FileType = {
  id: string; // uuid
  name: string;
  dataUrl: string;
};

export const FileUpload: React.FC<{
  onFileUpload: (filesInfo: FileType[]) => void;
}> = ({ onFileUpload }) => {
  const resizeConfig = {
    quality: 0.7,
    maxWidth: 1000,
    maxHeight: 1000,
  };

  const processFile = useCallback((file: File) => {
    return new Promise<FileType>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({
          id: uuid() as string,
          name: file.name,
          dataUrl: reader.result as string,
        });
      };
      reader.onerror = reject;

      if (file.type.startsWith("image/") && file.size > 4 * 1024 * 1024) {
        readAndCompressImage(file, resizeConfig)
          .then((resizedImage) => {
            reader.readAsDataURL(resizedImage);
          })
          .catch(reject);
      } else {
        // Directly read the file if it's not an image or smaller than 4MB
        reader.readAsDataURL(file);
      }
    });
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      Promise.all(acceptedFiles.map((file) => processFile(file)))
        .then((filesInfo) => onFileUpload(filesInfo))
        .catch((error) => console.error("Error processing files", error));
    },
    [processFile, onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
      },
      multiple: false, // Allow multiple files
    });

  return (
    <Box
      {...getRootProps()}
      p={4}
      border="2px dashed"
      borderColor={isDragActive ? "blue.300" : "gray.300"}
      transition="all 0.2s ease"
      _hover={{
        backgroundColor: "blue.50",
        borderColor: "blue.500",
      }}
      textAlign="center"
      borderRadius="md"
      cursor="pointer"
      bg={isDragActive ? "blue.50" : "gray.50"}
      w="full"
      minH="100px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      border={
        isDragActive
          ? isDragReject
            ? "2px dashed red"
            : "2px dashed #3182ce"
          : "2px dashed rgba(0,0,0,0.2)"
      }
      fontSize="md"
      color="gray.500"
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        isDragReject ? (
          <Text color="red.500">Nur Bilder und PDFs sind erlaubt</Text>
        ) : (
          <Text color="#3182ce">Drop it like it's hot! 🌶️</Text>
        )
      ) : (
        <>
          <Text display={{ base: "none", md: "block" }}>
            Ziehe ein Bild hierher oder klicke hier, um eines hochzuladen.
          </Text>
          <Text display={{ base: "block", md: "none" }}>
            Klicke hier, um ein Bild aufzunehmen oder hochzuladen.
          </Text>
        </>
      )}
    </Box>
  );
};
