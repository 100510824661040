export const TITLES = [
  "Abg.z.NR",
  "ADir.",
  "AR",
  "BA*",
  "Bakk. techn.*",
  "BBA*",
  "Bea.",
  "BEd*",
  "B.Eng.*",
  "Bgm.",
  "Bmstr.",
  "B.phil",
  "BSc*",
  "Dr.",
  "Dres.",
  "DDr.",
  "Dr. h.c.",
  "Dr. iur.",
  "Dr. med.univ.",
  "Dr. med.vet.",
  "Dr. mont.",
  "Dr. phil.",
  "Dr. rer.nat.techn.",
  "Dr. rer.pol.",
  "Dr. rer.soc.oec.",
  "Dr. techn.",
  "Dr. theol.",
  "Dipl.-Chem.",
  "Dipl.-Dolm.",
  "Dipl.-Gwl",
  "Dipl.-Hdl.",
  "Dipl.-HLFL-Ing.",
  "Dipl.Holzw.",
  "Dipl.-Ing., DI",
  "Dipl.-Ing. (FH)",
  "Dipl.-Päd.",
  "Dir.",
  "EMHRD*",
  "Gem. R.",
  "GR",
  "Hon.Prof.",
  "HR",
  "Ing.",
  "KommR",
  "LL.B.*",
  "LL.M.*",
  "Mag.",
  "Maga.",
  "MA*",
  "Mag. (FH)",
  "Mag. arch.",
  "Mag. iur.",
  "Mag. pharm.",
  "Mag. phil.",
  "Mag. rer.nat.",
  "Mag. rer.soc.oec.",
  "Mag. theol.",
  "M.A.I.S.*",
  "MBA*",
  "MedR",
  "MIB*",
  "MSc*",
  "Parl. R",
  "PhD",
  "Prof.",
  "StSekr.",
  "Univ.-Ass.",
  "Univ.-Doz.",
  "Univ.-Lekt.",
  "Univ.-Prof.",
];

let TITLES_PREFIX = [];

TITLES.forEach((title) => {
  if (!title.includes("*")) {
    TITLES_PREFIX = [...TITLES_PREFIX, { text: title, value: title }];
  }
});

let TITLES_SUFFIX = [];

TITLES.forEach((title) => {
  if (title.includes("*")) {
    const value = title.replace("*", "");

    TITLES_SUFFIX = [...TITLES_SUFFIX, { text: value, value: value }];
  }
});

TITLES_PREFIX = [{ text: "Kein Titel", value: "" }, ...TITLES_PREFIX];
TITLES_SUFFIX = [{ text: "Kein Titel", value: "" }, ...TITLES_SUFFIX];

export const getPrefix = () => {
  return TITLES_PREFIX;
};

export const getSuffix = () => {
  return TITLES_SUFFIX;
};
